import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

function Export({ canvas, isActive, setActiveShape }) {

  // const canvas = props.canvas;
  function exportAsImage() {
    canvas.exportAsImage();
    canvas.selectTool();
    setActiveShape("select")
  }
  // function exportAsPDF() {
  //   canvas.exportAsPDF();
  // }

  return (
    <div>
      <Link
        to="#"
        onClick={exportAsImage}
      >
        <i
          className="icon-download02"
          data-tip="Export Board" data-for="exportTooltip"
        ></i>
        <ReactTooltip className="commonTooltip" id="exportTooltip" place="top" type="dark" effect='solid' />
      </Link>
    </div>
  );
}

export default Export;
