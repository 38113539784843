import { useState } from "react";

function GetStarted({ controller }) {
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  function onNameChanged(e) {
    let msg = validation(e.target.value);
    if(msg){
      setError(msg);
    }else{
      setName(e.target.value);
      setError("");
    }
  }

  function registerInRoom(e) {
    e.preventDefault();
    
    if (!error && name.length>=3 && name.length<=30) {
      controller(e, name);
    }else{
      setError("Length of name should be between 3 to 30");
    }
  }

  function validation(name){
    if(name.length < 3 || name.length > 30){
      return "Length of name should be between 3 to 30";
    }

    if((/[^a-zA-Z]/.test(name))){
      return "Name should not contain number or special char";
    }

    return false;
  }

  return (
    <div className="getStarted__box text-center">
      <h1 className="font-hbd mb-4">Get Started</h1>
      <form onSubmit={registerInRoom}>
        <div className="form-group mb-4">
          <input
            type="text"
            onChange={onNameChanged}
            className="form-control"
            placeholder="Enter Name"
            name=""
          />
          <span style={{ color: "red" }}>{error}</span>
        </div>
        <input
          type="submit"
          className="btn btn-primary"
          value="Start on Whiteboard"
        />
      </form>
    </div>
  );
}

export default GetStarted;