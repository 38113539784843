import React, { useContext, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { SocketContext } from "../../context";
import GetStarted from "../../components/GetStarted";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slices/userSlice";

function Home({ history }) {
  const { socket, socketInstance } = useContext(SocketContext);
  const dispatch = useDispatch();

  let createRoom = useCallback((e, name) => {
    
    socket.on("room_id", (data) => {
      dispatch(setUser(data));
      history.replace(`/whiteboard/${data.roomId}`);
    });

    socket.emit("create_room", name);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => socketInstance.unSubscribeAllEvent(["create_room", "room_id"]);
    // eslint-disable-next-line
  }, []);

  return (
    <main className="main-content getStarted">
      <div className="container">
        <div className="getStarted__inner d-flex justify-content-center align-items-center">
          <GetStarted controller={createRoom} />
        </div>
      </div>
    </main>
  );
}

export default withRouter(Home);
