const stickyNotes = {
  overText: "",
  groupObject: null,
  textBox: null,
  textBoxChange: false,
  editingText: false,
  addStickyNoteSettings(group, canvas) {
    const invisibleControls = ["mt", "mr", "ml", "mb", "mtr"];
    invisibleControls.forEach((side) => {
      group.setControlVisible(side, true);
    });

    group.on("mousedblclick", () => {
      canvas.on("mouse:down", () => {
        if (this.editingText === true) {
          if (this.editingText === true && this.overText === false) {
            this.textBox.exitEditing();

            canvas.remove(this.textBox);

            this.groupObject.addWithUpdate(this.textBox);

            canvas.setActiveObject(this.groupObject);

            this.editingText = false;
            this.groupObject.set({
              selectable: true,
              evented: true,
            });
            this.groupObject.set({
              hasControls: false,
              hasBorders: false,
              lockMovementX: true,
              lockMovementY: true,
            });
            canvas.fire("notes:modified", { target: this.groupObject });
            this.groupObject = null;
            this.textBox = null;
            this.textBoxChange = false;
            canvas.renderAll();
          }
        }
      });
      group.set({
        selectable: false,
        evented: false,
      });

      this.editingText = true;
      this.groupObject = group;

      this.textBox = group.item(1);
      this.textBox.scaleX *= group.scaleX;
      this.textBox.scaleY *= group.scaleY;
      this.textBox.left = group.left + 10 * this.textBox.scaleX;
      this.textBox.top = group.top + 10 * this.textBox.scaleY;

      group.remove(group.item(1));
      canvas.add(this.textBox).setActiveObject(this.textBox);
      this.textBox.enterEditing();

      canvas.renderAll();
    });
  },

  addTextBoxSettings(textBox, group, canvas) {
    textBox.on("mouseover", () => {
      this.overText = true;
    });
    textBox.on("mouseout", () => {
      this.overText = false;
    });
    textBox.on("changed", () => {
      this.textBoxChange = true;
      this.fontResizeStickyNote(textBox, group, canvas);
    });

    textBox.set({
      hasControls: false,
      hasBorders: false,
      lockMovementX: true,
      lockMovementY: true,
    });
  },

  fontResizeStickyNote(textBox, group, canvas) {
    let lineNumber = 0;
    let maxLineTextWidth = 0;

    // Calculation of the maximum line length
    textBox._textLines.forEach(() => {
      const LineTextWidth = textBox.getLineWidth(lineNumber);
      if (LineTextWidth > maxLineTextWidth) {
        maxLineTextWidth = LineTextWidth;
      }
      lineNumber += 1;
    });
    textBox.width = maxLineTextWidth;

    // Automatic change of the FontSize
    const maxfixedWidth = group.item(0).width - 20;
    const maxfixedHeight = group.item(0).height - 20;
    const maxfontSize = group.item(0).height - 20;

    let newfontSize = textBox.fontSize;
    // if the text width is too long or too short
    newfontSize *= maxfixedWidth / (textBox.width + 1);
    if (newfontSize > maxfontSize) {
      newfontSize = maxfontSize;
      textBox.set({ fontSize: maxfontSize });
    } else {
      textBox.set({ fontSize: newfontSize });
    }
    textBox.width = maxfixedWidth;

    // if the text height is too long or too short
    while (textBox.height > maxfixedHeight) {
      const scale = textBox.height / maxfixedHeight;
      if (textBox.fontSize > maxfontSize) {
        textBox.fontSize = maxfontSize;
      }
      if (scale >= 4) {
        newfontSize -= scale;
      } else if (scale < 4 && scale >= 1) {
        newfontSize -= 4;
      } else {
        newfontSize -= 1;
      }

      textBox.set({ fontSize: newfontSize });
    }
    canvas.renderAll();
  },
};

export default stickyNotes;
