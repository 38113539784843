import Routes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import 'antd/dist/antd.css';
import SocketClass from "./lib/socket";
import { SocketContext } from "./context";
import "./utilities/common";

const SocketInstance = new SocketClass();
var socket ={
  socket:SocketInstance.getSocket(),
  socketInstance:SocketInstance
}

function App() {
  return (
    <SocketContext.Provider value={socket}>
      <Routes />
    </SocketContext.Provider>
  );
}

export default App;
