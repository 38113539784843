import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeComponent from "../pages/Home";
import WhiteboardComponent from "../pages/Whiteboard";
import NotFound from "../pages/NotFound";
import InvalidPage from "../pages/InvalidPage";
import SocketRoomLayout from "../layout/socketRoomLayout";

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <HomeComponent />
        </Route>
        <Route path="/whiteboard/:room">
          <SocketRoomLayout>
            <WhiteboardComponent />
          </SocketRoomLayout>
        </Route>
        <Route path="/invaid-room">
          <InvalidPage />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

export default Routes;
