import { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../context";
import Canvas from "../../lib/canvas";
import { withRouter } from "react-router-dom";
import Toolbar from "../../components/Toolbar";
import { useDispatch } from "react-redux";
import Header from "../../components/Header";
import { loadNewUser } from "../../redux/slices/roomUsersList";
import message from "antd/lib/message";

function Whiteboard({
  match,
  active,
  setActiveShape,
  historyChecker,
  setHistory,
}) {
  const { socket, socketInstance } = useContext(SocketContext);
  const [canvas, setCanvas] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (match.params.room) {
      initiateCanvas();
    } else {
      // TODO : Redirect to Not valid link page
    }
    // eslint-disable-next-line
    return () =>
      socketInstance.unSubscribeAllEvent([
        "object:added",
        "object:removed",
        "object:modified",
        "object:created",
        "object:skewed",
        "object:added",
        "active_sender",
        "new_user_joined",
        "on_canvas",
      ]);
    // eslint-disable-next-line
  }, []);

  let initiateCanvas = () => {
    const canvasData = new Canvas(
      "canvas",
      {
        selection: false,
        isDrawingMode: true,
        preserveObjectStacking: true,
        allowTouchScrolling: true,
        backgroundColor: "#F2F3F2",
      },
      socket,
      setActiveShape,
      setHistory,
      cb
    );
    setCanvas(() => canvasData);
    canvasData.setActiveShape("pencil");
    canvasData.freeDrawing();
  };

  function cb(type, msg, user) {
    if (type === "NEW_USER_JOIN") {
      dispatch(loadNewUser(user));
      message.info(msg);
    }
  }
  return (
    <div>
      <Header historyChecker={historyChecker} canvas={canvas}></Header>
      <Toolbar
        active={active}
        setActiveShape={setActiveShape}
        canvas={canvas}
      ></Toolbar>
      {/* <div
        className="mainWrapper d-block"
        style={{ minWidth: "100%", height: "100%" }}
      > */}
      <canvas id="canvas" />
      {/* </div> */}
    </div>
  );
}

export default withRouter(Whiteboard);
