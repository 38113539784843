import {
  Arrow,
  Draw,
  Eraser,
  Export,
  Notes,
  Select,
  Shape,
  Text,
  Uploader,
} from "../Whiteboard";

function Toolbar({ active, setActiveShape, canvas }) {

  return (
    <aside id="sidebar">
      <ul className="list-unstyled mb-0">
        <li>
          <Select
            setActiveShape={setActiveShape}
            isActive={active}
            canvas={canvas}
          ></Select>
        </li>
        <li className="dropdown">
          <Draw
            setActiveShape={setActiveShape}
            isActive={active}
            canvas={canvas}
          ></Draw>
        </li>
        <li className="dropdown">
          <Arrow
            setActiveShape={setActiveShape}
            isActive={active}
            canvas={canvas}
          ></Arrow>
        </li>
        <li>
          <Eraser
            setActiveShape={setActiveShape}
            isActive={active}
            canvas={canvas}
          ></Eraser>
        </li>
        <li className="dropdown">
          <Shape
            setActiveShape={setActiveShape}
            isActive={active}
            canvas={canvas}
          ></Shape>
        </li>
        <li>
          <Text
            setActiveShape={setActiveShape}
            isActive={active}
            canvas={canvas}
          ></Text>
        </li>
        <li>
          <Notes isActive={active} setActiveShape={setActiveShape} canvas={canvas}></Notes>
        </li>
        <li className="dropdown">
          <Uploader setActiveShape={setActiveShape} canvas={canvas}></Uploader>
        </li>
        <li className="dropdown">
          <Export setActiveShape={setActiveShape} canvas={canvas}></Export>
        </li>
      </ul>
    </aside>
  );
}

export default Toolbar;
