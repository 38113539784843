import React, { useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import Button from "antd/lib/button";
import Model from "antd/lib/modal";
import ShareModal from "../../components/Model/shareModal";
import ReactTooltip from 'react-tooltip';
import modalNotification from "../../utilities/notifications";
import { SocketContext } from "../../context";
import { useSelector } from "react-redux";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Model;

function Header(props) {
  const [modal, setModal] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const { socket } = useContext(SocketContext);
  const user = useSelector(state => state.userReducer);
  const canvas = props.canvas;

  function undo() {
    canvas.undo();
  }

  function showConfirm() {
    confirm({
      title: 'Do you want to end this session?',
      icon: <ExclamationCircleOutlined />,
      content: 'Once you end this session. You will no longer able to access.',
      centered: true,
      onOk() {
        onSessionEnd();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  function redo() {
    canvas.redo();
  }

  function onClickRequest() {
    setModal(true);
  }

  function onCancel() {
    setModal(false);
  }

  function onSessionEnd() {
    if (user.userType === "host") {
      socket.emit("end-session");
    } else {
      leaveRoom();
    }
  }

  function leaveRoom() {
    props.history.push("/");
  }

  function toggleFullScreen() {
    try {
      var doc = window.document;
      var docEl = doc.documentElement;

      var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
      var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

      if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        setFullScreen(true)
        requestFullScreen.call(docEl);
      } else {
        setFullScreen(false)
        cancelFullScreen.call(doc);
      }
    } catch (err) {
      modalNotification({
        type: "error",
        message: "Unable to access full screen",
        description: "Full screen is not supported by browser"
      })
    }
  }

  return (
    <header className="header">
      <ShareModal visible={modal} onCancel={onCancel} />
      <div className="logo">
        <h1 className="font-hbd mb-0"><b>White Board</b></h1>
      </div>
      <ul className="list-inline mb-0 d-flex align-items-center">
        <li className="list-inline-item">
          <Link
            className={`${props?.historyChecker?.undo ? "active" : "disabled-link"
              }`}
            to="#"
            onClick={undo}
          >
            <i
              className="icon-undo"
              data-tip="Undo" data-for="undoTooltip"
            ></i>
            <ReactTooltip className="commonTooltip" id="undoTooltip" place="bottom" type="dark" effect='solid' />
          </Link>
        </li>
        <li className="list-inline-item">
          <Link
            to="#"
            className={`${props?.historyChecker?.redo ? "active" : "disabled-link"
              }`}
            onClick={redo}
          >
            <i
              className="icon-redo"
              data-tip="Redo" data-for="redoTooltip"
            ></i>
            <ReactTooltip className="commonTooltip" id="redoTooltip" place="bottom" type="dark" effect='solid' />
          </Link>
        </li>
        <li className="list-inline-item">
          <Link
            to="#"
            style={{ cursor: "pointer" }}
            className={fullScreen ? "active" : ""}
            onClick={toggleFullScreen}
          >
            <i className="icon-fullscreen" data-tip={`${fullScreen ? "Exit Fullscreen" : "Enter Fullscreen"}`} data-for="fullscreen"></i>
            <ReactTooltip className="commonTooltip" id="fullscreen" place="bottom" type="dark" effect='solid' />
          </Link>
        </li>
      </ul>
      <Button onClick={onClickRequest} type="primary" size="large">
        <b>Share</b>
      </Button>
      {/* <Button onClick={showConfirm} type="primary" size="large" danger>
        {user.userType === "host" ? "End Session" : "Leave"}
      </Button> */}
    </header>
  );
}

export default withRouter(Header);
