import CommonModal from "./commonContainer";
import Button from "antd/lib/button";
import Input  from "antd/lib/input";
import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import {copyLink} from "../../utilities/common";

function ShareModal({ visible, match, onCancel }) {
  const [link, setLink] = useState("");
  const [text, setText] = useState("Copy Link");

  useEffect(() => {
    setLink(window.location.href);
  }, []);

  function copyText() {
    copyLink(window.location.href);
    setText("Copied");
    setTimeout(() => {
      setText("Copy link");
    }, 5000);
  }

  return (
    <CommonModal visible={visible} onCancel={onCancel}>
      <h5>Invite link of Whiteboard</h5>
      <div className="d-flex flex-row mt-4 mb-3">
        <Input
          value={link}
          disabled={true}
          size="large"
        />
        <Button type="primary" onClick={copyText} size="large">
          {text}
        </Button>
      </div>
    </CommonModal>
  );
}

export default withRouter(ShareModal);
