import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

function Select({ canvas, isActive, setActiveShape }) {

  function selectTool() {
    setActiveShape("select")
    canvas.selectTool();
  }
  return (
    <Link to="#" className={isActive === "select" ? "active" : ""} onClick={selectTool}>
      <i
        className="icon-near-me"
        data-tip="Select" data-for="selectTooltip"
      ></i>
      <ReactTooltip className="commonTooltip" id="selectTooltip" place="top" type="dark" effect='solid' />
    </Link>
  );
}

export default Select;
