export const setSessionStorageToken = (token) => {

};

export const getSessionStorageToken = () => {

};

export const removeSessionStorageToken = () => {

};

export const getLocalStorageLanguage = () => {

};

export const copyLink = (text) => {
  window?.navigator?.clipboard.writeText(text);
  //Fallback for older browser
  window?.clipboardData?.setData("Text", text);
};
