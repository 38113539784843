const customFabric = (fabric) => {
  // Extended fabric line class for double head arrow
  fabric.LineArrow = fabric.util.createClass(fabric.Line, {
    type: "lineArrow",
    initialize(element, options) {
      options || (options = {});
      this.callSuper("initialize", element, options);
    },

    toObject() {
      return fabric.util.object.extend(this.callSuper("toObject"), {
        id: this.id,
      });
    },

    _render(ctx) {
      this.ctx = ctx;
      this.callSuper("_render", ctx);
      let p = this.calcLinePoints();
      let xDiff = this.x2 - this.x1;
      let yDiff = this.y2 - this.y1;
      let angle = Math.atan2(yDiff, xDiff);
      this.drawArrow(angle, p.x2, p.y2);
      ctx.save();
      xDiff = -this.x2 + this.x1;
      yDiff = -this.y2 + this.y1;
      angle = Math.atan2(yDiff, xDiff);
      this.drawArrow(angle, p.x1, p.y1);
    },

    drawArrow(angle, xPos, yPos) {
      this.ctx.save();
      this.ctx.translate(xPos, yPos);
      this.ctx.rotate(angle);
      this.ctx.beginPath();
      // Move 5px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
      this.ctx.moveTo(7, 0);
      this.ctx.lineTo(-7, 7);
      this.ctx.lineTo(-7, -7);
      this.ctx.closePath();
      this.ctx.fillStyle = this.stroke;
      this.ctx.fill();
      this.ctx.restore();
    },
  });

  fabric.LineArrow.fromObject = function (object, callback) {
    callback &&
      callback(
        new fabric.LineArrow(
          [object.x1, object.y1, object.x2, object.y2],
          { ...object, lockMovementX: true, lockMovementY: true, hasControls: false }
        )
      );
  };
  fabric.LineArrow.async = true;

  // Extended fabric line class for single head arrow
  fabric.Arrow = fabric.util.createClass(fabric.Line, {
    type: "Arrow",
    initialize(element, options) {
      options || (options = {});
      this.callSuper("initialize", element, options);
    },

    toObject() {
      return fabric.util.object.extend(this.callSuper("toObject"), {
        id: this.id,
      });
    },

    _render(ctx) {
      this.ctx = ctx;
      this.callSuper("_render", ctx);
      let p = this.calcLinePoints();
      let xDiff = this.x2 - this.x1;
      let yDiff = this.y2 - this.y1;
      let angle = Math.atan2(yDiff, xDiff);
      this.drawArrow(angle, p.x2, p.y2);
      ctx.save();
    },

    drawArrow(angle, xPos, yPos) {
      this.ctx.save();
      this.ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
      this.ctx.rotate(angle);
      this.ctx.beginPath();
      // Move 5px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
      this.ctx.moveTo(7, 0);
      this.ctx.lineTo(-7, 7);
      this.ctx.lineTo(-7, -7);
      this.ctx.closePath();
      this.ctx.fillStyle = this.stroke;
      this.ctx.fill();
      this.ctx.restore();
    },
  });

  fabric.Arrow.fromObject = function (object, callback) {
    callback &&
      callback(
        new fabric.Arrow([object.x1, object.y1, object.x2, object.y2],  { ...object, lockMovementX: true, lockMovementY: true, hasControls: false })
      );
  };
  fabric.Arrow.async = true;

  // extend curved line arrow

  fabric.LineWithArrow = fabric.util.createClass(fabric.Line, {
    type: "lineWithArrow",

    initialize(element, options) {
      options || (options = {});
      this.callSuper("initialize", element, options);

      // Set default options
      this.set({
        hasBorders: true,
        hasControls: false,
      });
    },

    _render(ctx) {
      // this.callSuper('_render', ctx);
      ctx.save();
      const xDiff = this.x2 - this.x1;
      const yDiff = this.y2 - this.y1;
      const angle = Math.atan2(yDiff, xDiff);
      ctx.translate(xDiff / 2, yDiff / 2);
      ctx.rotate(angle);
      ctx.beginPath();
      // Move 5px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
      ctx.moveTo(5, 0);
      ctx.lineTo(-5, 5);
      ctx.lineTo(-5, -5);
      ctx.closePath();
      ctx.fillStyle = this.stroke;
      ctx.fill();
      ctx.restore();
      var p = this.calcLinePoints();
      var point = this.pointOnLine(
        this.point(p.x2, p.y2),
        this.point(p.x1, p.y1),
        10
      );
      this.wavy(this.point(p.x1, p.y1), point, this.point(p.x2, p.y2), ctx);
      ctx.stroke();
    },

    point(x, y) {
      return {
        x: x,
        y: y,
      };
    },

    wavy(from, to, endPoint, ctx) {
      var cx = 0,
        cy = 0,
        fx = from.x,
        fy = from.y,
        tx = to.x,
        ty = to.y,
        i = 0,
        step = 4,
        waveOffsetLength = 0,
        ang = Math.atan2(ty - fy, tx - fx),
        distance = Math.sqrt((fx - tx) * (fx - tx) + (fy - ty) * (fy - ty)),
        amplitude = -10,
        f = (Math.PI * distance) / 30;

      for (i; i <= distance; i += step) {
        waveOffsetLength = Math.sin((i / distance) * f) * amplitude;
        cx =
          from.x +
          Math.cos(ang) * i +
          Math.cos(ang - Math.PI / 2) * waveOffsetLength;
        cy =
          from.y +
          Math.sin(ang) * i +
          Math.sin(ang - Math.PI / 2) * waveOffsetLength;
        i > 0 ? ctx.lineTo(cx, cy) : ctx.moveTo(cx, cy);
      }
      ctx.lineTo(to.x, to.y);
      ctx.lineTo(endPoint.x, endPoint.y);
    },

    pointOnLine(point1, point2, dist) {
      var len = Math.sqrt(
        (point2.x - point1.x) * (point2.x - point1.x) +
        (point2.y - point1.y) * (point2.y - point1.y)
      );
      var t = dist / len;
      var x3 = (1 - t) * point1.x + t * point2.x,
        y3 = (1 - t) * point1.y + t * point2.y;
      return new fabric.Point(x3, y3);
    },

    toObject() {
      return fabric.util.object.extend(this.callSuper("toObject"), {
        customProps: this.customProps,
        id: this.id,
        lockMovementX: true, lockMovementY: true, hasControls: false
      });
    },
  });

  fabric.LineWithArrow.fromObject = function (object, callback) {
    callback &&
      callback(
        new fabric.LineWithArrow(
          [object.x1, object.y1, object.x2, object.y2],
          { ...object, lockMovementX: true, lockMovementY: true, hasControls: false }
        )
      );
  };
  fabric.LineWithArrow.async = true;

  return fabric;
};

export default customFabric;
