import React, { useState, useEffect, useContext, useCallback } from "react";
import { withRouter } from "react-router";
import { SocketContext } from "../context";
import message from "antd/lib/message";
import GetStartedModal from "../components/Model/getStartedModal";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../redux/slices/userSlice";
import { removeUser } from "../redux/slices/roomUsersList";
import Loading from "../components/Loader/fullScreenLoader";

function SocketRoomLayout({ children, match, history }) {
  const { socket, socketInstance } = useContext(SocketContext);
  const [alert, setAlert] = useState("loading");
  const [openEvents, setOpenEvents] = useState([]);
  const userSocket = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const [active, setActive] = useState({ activeTool: "select" });
  const [historyChecker, setHistoryChecker] = useState({ undo: false, redo: false });

  function setActiveShape(shape) {
    setActive(shape);
  }

  function setHistory(data) {
    setHistoryChecker({ ...historyChecker, ...data });
  }

  useEffect(() => {
    if (match.params.room) {
      onRoomJoinFail();
      onRoomNotFound();
      onSocketRoomJoin();
      onRoomDeleted();
      onUserLeave();
      if (
        userSocket &&
        userSocket.userId &&
        userSocket.roomId === match.params.room
      ) {
        joinRoom(match.params.room, userSocket.name, userSocket.userId);
      } else {
        setAlert("USER_NOT_EXIT");
      }
    } else {
      setAlert("loading");
    }
    
    return () => socketInstance.unSubscribeAllEvent(openEvents);
    // eslint-disable-next-line
  }, []);

  let joinRoomWithName = (e, name) => {
    joinRoom(match.params.room, name, userSocket.roomId);
  };

  let pushEvents = (e) => {
    setOpenEvents([...openEvents, e]);
  };

  let onRoomNotFound = () => {
    socket.on("room_not_found", (event) => {
      setAlert(event);
      history.push("/invaid-room");
    });
    pushEvents("room_not_found");
    // eslint-disable-next-line
  };

  let joinRoom = useCallback((roomId, name, socketId) => {
    socket.emit("join_room", roomId, name, userSocket.userId);
    // eslint-disable-next-line
  }, []);

  let onRoomJoinFail = useCallback(() => {
    socket.on("on_room_error", (event) => {
      setAlert(event);
    });
    pushEvents("on_room_error");
    // eslint-disable-next-line
  }, []);

  let onSocketRoomJoin = useCallback(() => {
    socket.on("on_room_join", (event) => {
      dispatch(setUser(event));
      setAlert(null);
    });
    pushEvents("on_room_join");
    // eslint-disable-next-line
  }, []);

  let onUserLeave = useCallback(() => {
    socket.on("on_user_leave", (user) => {
      message.info(`${user.name} leave the room`);
      dispatch(removeUser(user.userId));
    });
    pushEvents("on_user_leave");
    // eslint-disable-next-line
  }, []);

  let onRoomDeleted = useCallback(() => {
    socket.on("delete-room", () => {
      setAlert("out")
      setTimeout(() => {
        history.replace("/");
        socketInstance.unSubscribeAllEvent(openEvents)
      }, 1000)
    })
    pushEvents("delete-room");
    // eslint-disable-next-line
  }, []);

  if (alert === "loading") {
    return <Loading><h4>Loading...</h4></Loading>;
  } else if (alert === "USER_NOT_EXIT") {
    return (
      <GetStartedModal
        visible={alert === "USER_NOT_EXIT"}
        controller={joinRoomWithName}
      />
    );
  } else if (alert === "out") {
    return <Loading>
      <h4>Ending Session...</h4>
    </Loading>
  } else {
    return (
      <>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            active: active,
            historyChecker: historyChecker,
            setHistory: setHistory,
            setActiveShape: setActiveShape,
          });
        })}
      </>
    );
  }
}

export default withRouter(SocketRoomLayout);
