import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: null,
  roomId: null,
  socketId: null,
  name: null,
  userType: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    },
    deleteUser: (state, action) => {
      return initialState;
    },
  },
});
export const { setUser, deleteUser } = userSlice.actions;
export default userSlice.reducer;
