import { createSlice } from "@reduxjs/toolkit";

const initUserList = {
    activeUsers: []
}


const UserList = createSlice({
    name:"userList",
    initialState:initUserList,
    reducers:{

        loadInitialUsers:(state, action)=>{
            return{
                activeUsers:[...action.payload]
            }
        },
        removeUser:(state, action)=>{
            let newUserList = state.activeUsers.filter((user)=>user.userId!==action.payload);
            return{
                activeUsers:[...newUserList]
            }
        },
        loadNewUser:(state, action)=>{
           
            return {
                activeUsers:[...state.activeUsers,action.payload]
            }
        }
    }
})

export const {loadInitialUsers, removeUser, loadNewUser} = UserList.actions;
export default UserList.reducer;