import notification from "antd/lib/notification";

const modalNotification = ({ type, message, description, duration }) => {
  var transformedText =
    description.charAt(0).toUpperCase() + description.slice(1);
  notification[type]({
    message,
    description: transformedText,
    duration: duration || 5,
  });
};

export default modalNotification;