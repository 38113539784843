import { Link } from "react-router-dom";
import stickynoteBlack from "../../../assets/images/stickynote/black.svg";
import stickynoteBlueLight from "../../../assets/images/stickynote/blue-light.svg";
import stickynoteBlue from "../../../assets/images/stickynote/blue.svg";
import stickynoteGreenDark from "../../../assets/images/stickynote/green-dark.svg";
import stickynoteGreenLight from "../../../assets/images/stickynote/green-light.svg";
import stickynoteGreen from "../../../assets/images/stickynote/green.svg";
import stickynoteGrey from "../../../assets/images/stickynote/grey.svg";
import stickynoteHotPink from "../../../assets/images/stickynote/hot-pink.svg";
import stickynoteOceanGreen from "../../../assets/images/stickynote/ocean-green.svg";
import stickynoteOrange from "../../../assets/images/stickynote/orange.svg";
import stickynotePink from "../../../assets/images/stickynote/pink.svg";
import stickynotePurple from "../../../assets/images/stickynote/purple.svg";
import stickynoteRed from "../../../assets/images/stickynote/red.svg";
import stickynoteSkyBlue from "../../../assets/images/stickynote/sky-blue.svg";
import stickynoteYellow from "../../../assets/images/stickynote/yellow.svg";
import stickynoteYellowLight from "../../../assets/images/stickynote/yellow-light.svg";
import { notesColor } from "../../../lib/canvas";
import ReactTooltip from 'react-tooltip';

const childrenShapes = [
                        notesColor.GREY,
                        notesColor.YELLOW_LIGHT,
                        notesColor.GREEN_LIGHT,
                        notesColor.YELLOW,
                        notesColor.GREEN,
                        notesColor.ORANGE, 
                        notesColor.GREEN_DARK,
                        notesColor.RED,
                        notesColor.OCEAN_GREEN,
                        notesColor.HOT_PINK,
                        notesColor.SKY_BLUE,
                        notesColor.PINK,
                        notesColor.BLUE_LIGHT,
                        notesColor.PURPLE,
                        notesColor.BLUE,
                        notesColor.BLACK
                      ];

function Notes({ canvas, isActive, setActiveShape }) {
  // const canvas = canvas;
  
  function createStickyNotes(event, color) {
    setActiveShape(color);
    canvas.createNotes(color);
  }

  function setActiveTool(e){
    console.log(isActive,childrenShapes);
    if(!childrenShapes.includes(isActive)){
      createStickyNotes(e,notesColor.GREY);
    }
  }
  return (
    <div>
      <Link
        className="dropdown-toggle nav-link"
        to="#"
        id="dropdownMenuLink03"
        data-toggle="dropdown"
        aria-haspopup="true"
        onFocus={setActiveTool}
        className={`dropdown-toggle nav-link ${childrenShapes.includes(isActive) ? "active" : ""}`}
        aria-expanded="false"
      >
        <i
          className="icon-notsicon2"
         data-tip="Add Notes" data-for="notesTooltip"
        ></i>
        <ReactTooltip className="commonTooltip" id="notesTooltip" place="top" type="dark" effect='solid' />
      </Link>
      <div
        className="dropdown-menu noteDropdown"
        aria-labelledby="dropdownMenuLink03"
      >
        <ul className="list-inline">
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.GREY ? "active" : ""}`} 
              onClick={(event) => createStickyNotes(event, notesColor.GREY)}
            >
              <img src={stickynoteGrey} className="img-fluid" alt="sticky"/>
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.YELLOW_LIGHT ? "active" : ""}`} 
              onClick={(event) =>
                createStickyNotes(event, notesColor.YELLOW_LIGHT)
              }
            >
              <img src={stickynoteYellowLight} className="img-fluid" alt="yellow-light" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.GREEN_LIGHT ? "active" : ""}`} 
              onClick={(event) =>
                createStickyNotes(event, notesColor.GREEN_LIGHT)
              }
            >
              <img src={stickynoteGreenLight} className="img-fluid" alt="green-light" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.YELLOW ? "active" : ""}`} 
              onClick={(event) => createStickyNotes(event, notesColor.YELLOW)}
            >
              <img src={stickynoteYellow} className="img-fluid" alt="yellow" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.GREEN ? "active" : ""}`} 
              onClick={(event) => createStickyNotes(event, notesColor.GREEN)}
            >
              <img src={stickynoteGreen} className="img-fluid" alt="green" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.ORANGE ? "active" : ""}`} 
              onClick={(event) => createStickyNotes(event, notesColor.ORANGE)}
            >
              <img src={stickynoteOrange} className="img-fluid" alt="orange" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.GREEN_DARK ? "active" : ""}`} 
              onClick={(event) =>
                createStickyNotes(event, notesColor.GREEN_DARK)
              }
            >
              <img src={stickynoteGreenDark} className="img-fluid" alt="green-dark" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.RED ? "active" : ""}`} 
              onClick={(event) => createStickyNotes(event, notesColor.RED)}
            >
              <img src={stickynoteRed} className="img-fluid" alt="red" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.OCEAN_GREEN ? "active" : ""}`} 
              onClick={(event) =>
                createStickyNotes(event, notesColor.OCEAN_GREEN)
              }
            >
              <img src={stickynoteOceanGreen} className="img-fluid" alt="ocean-green" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.HOT_PINK ? "active" : ""}`} 
              onClick={(event) => createStickyNotes(event, notesColor.HOT_PINK)}
            >
              <img src={stickynoteHotPink} className="img-fluid" alt="hot-pink" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.SKY_BLUE ? "active" : ""}`} 
              onClick={(event) => createStickyNotes(event, notesColor.SKY_BLUE)}
            >
              <img src={stickynoteSkyBlue} className="img-fluid" alt="sky-blue" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.PINK ? "active" : ""}`} 
              onClick={(event) => createStickyNotes(event, notesColor.PINK)}
            >
              <img src={stickynotePink} className="img-fluid" alt="pink" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.BLUE_LIGHT ? "active" : ""}`}
              onClick={(event) =>
                createStickyNotes(event, notesColor.BLUE_LIGHT)
              }
            >
              <img src={stickynoteBlueLight} className="img-fluid" alt="blue-light" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.PURPLE ? "active" : ""}`}
              onClick={(event) => createStickyNotes(event, notesColor.PURPLE)}
            >
              <img src={stickynotePurple} className="img-fluid" alt="purple" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.BLUE ? "active" : ""}`}
              onClick={(event) => createStickyNotes(event, notesColor.BLUE)}
            >
              <img src={stickynoteBlue} className="img-fluid" alt="blue" />
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`dropdown-item ${isActive === notesColor.BLACK ? "active" : ""}`}
              onClick={(event) => createStickyNotes(event, notesColor.BLACK)}
            >
              <img src={stickynoteBlack} className="img-fluid" alt="black" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Notes;
