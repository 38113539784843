import { useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

const childrenShapes = ["heart", "square", "rectangle", "pantagon", "polygon", "circle", "fontagon", "triangle"];

function Shape({ canvas, isActive, setActiveShape }) {
  // const canvas = props.canvas;

  const [color, setColor] = useState("#000000");
  const [activeColor, setActiveColor] = useState(null)

  function changeColor(event, colorValue) {
    let color = colorValue ? colorValue : event.target.value;
    if (canvas) {
      canvas.changeShapeFill(color);
    }
    setActiveColor(color);
    setColor(() => color);
  }

  function drawCircle() {
    setActiveShape("circle")
    canvas.drawCircle(activeColor);
  }
  function drawTriangle() {
    setActiveShape("triangle")
    canvas.drawTriangle(activeColor);
  }
  function drawHeart() {
    setActiveShape("heart")
    canvas.drawHeart(activeColor);
  }
  function drawRoundedRectangle() {
    setActiveShape("rectangle")
    canvas.drawRoundedRectangle(activeColor);
  }
  function drawPolygon(edge) {
    setActiveShape("polygon")
    canvas.drawPolygon(edge, activeColor);
  }
  function drawSquare() {
    setActiveShape("square")
    canvas.drawSquare(activeColor);
  }
  function drawRhomeBus() {
    setActiveShape("fontagon")
    canvas.drawRhomeBus(activeColor);
  }

  function setFillActive(e) {
    // e.preventDefault()
    if (canvas) {
      setActiveColor(canvas.getActiveShapeFill())
    }
  }

  function setActiveTool(e){
    if(!childrenShapes.includes(isActive)){
      drawHeart();
    }
  }

  return (
    <div>
      <Link
        to="#"
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        onFocus={setActiveTool}
        className={`dropdown-toggle ${childrenShapes.includes(isActive) ? "active" : ""}`}
        aria-expanded="false"
      >
        <div onClick={setFillActive}>
          <i
            className="icon-dubble-shape"
            data-tip="Shape" data-for="shapeTooltip"
          ></i>
          <ReactTooltip className="commonTooltip" id="shapeTooltip" place="top" type="dark" effect='solid' />
        </div>
      </Link>
      <div
        className="dropdown-menu arrowDropdown"
        aria-labelledby="dropdownMenuLink"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="shapeDropdown">
          <Link to="#" className={`dropdown-item ${isActive === "heart" ? "active" : ""}`} onClick={drawHeart}>
            <i className="icon-heart-outline"></i>
          </Link>
          <Link to="#" className={`dropdown-item ${isActive === "pantagon" ? "active" : ""}`} onClick={drawPolygon}>
            <i className="icon-pantagon-outline"></i>
          </Link>
          <Link to="#" className={`dropdown-item ${isActive === "triangle" ? "active" : ""}`} onClick={drawTriangle}>
            <i className="icon-triangle-outline"></i>
          </Link>
          <Link to="#" className={`dropdown-item ${isActive === "square" ? "active" : ""}`} onClick={drawSquare}>
            <i className="icon-square-outline"></i>
          </Link>
          <Link
            to="#"
            className={`dropdown-item ${isActive === "polygon" ? "active" : ""}`}
            onClick={drawPolygon.bind(this, 6)}
          >
            <i className="icon-polygon-outline"></i>
          </Link>
          <Link to="#" className={`dropdown-item ${isActive === "fontagon" ? "active" : ""}`} onClick={drawRhomeBus}>
            <i className="icon-fontagon-outline"></i>
          </Link>
          <Link to="#" className={`dropdown-item ${isActive === "circle" ? "active" : ""}`} onClick={drawCircle}>
            <i className="icon-circle-outline"></i>
          </Link>
          <Link to="#" className={`dropdown-item ${isActive === "rectangle" ? "active" : ""}`} onClick={drawRoundedRectangle}>
            <i className="icon-rectangle-outline"></i>
          </Link>
        </div>
        <ul className="list-inline colorList">
          <li className="list-inline-item">
            <Link
              to="#"
              className={`selectedColor ${activeColor === "#000000" ? "active" : ""}`}
              style={{ backgroundColor: "#000000" }}
              onClick={(event) => changeColor(event, "#000000")}
            ></Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`selectedColor ${activeColor === "#FFCA2E" ? "active" : ""}`}
              style={{ backgroundColor: "#FFCA2E" }}
              onClick={(event) => changeColor(event, "#FFCA2E")}
            ></Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`selectedColor ${activeColor === "#FB671F" ? "active" : ""}`}
              style={{ backgroundColor: "#FB671F" }}
              onClick={(event) => changeColor(event, "#FB671F")}
            ></Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`selectedColor ${activeColor === "#0920E8" ? "active" : ""}`}
              style={{ backgroundColor: "#0920E8" }}
              onClick={(event) => changeColor(event, "#0920E8")}
            ></Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`selectedColor ${activeColor === "#37BDFC" ? "active" : ""}`}
              style={{ backgroundColor: "#37BDFC" }}
              onClick={(event) => changeColor(event, "#37BDFC")}
            ></Link>
          </li>
          <li className="list-inline-item">
            <label className="addColor">
              <input
                type="color"
                id="addColor"
                name=""
                onChange={changeColor}
                value={color}
              />
              <i className="icon-addcircle-outline"></i>
            </label>
          </li>
        </ul>
        <div className="text-center border-top-1" onClick={() => changeColor("event", "transparent")} style={{ cursor: "pointer", fontSize: "0.85rem" }}>
          Reset Fill
        </div>
      </div>
    </div>
  );
}

export default Shape;
