import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

const childrenShapes = ["text"];
function Text({ canvas, isActive, setActiveShape }) {
  function addText() {
    //canvas.setTool(drawingTool.TEXT);
    setActiveShape("text")
    canvas.addText();
  }
  return (
    <Link to="#" onClick={addText} className={`${childrenShapes.includes(isActive) ? "active" : ""}`}>
      <i
        className="icon-text"
        data-tip="Text" data-for="textTooltip"
      ></i>
      <ReactTooltip className="commonTooltip" id="textTooltip" place="top" type="dark" effect='solid' />
    </Link>
  );
}

export default Text;
