import { fabric } from "fabric";
import "./fabric-history";
import stickyNotesEvents from "./stickyNotes";
import { v4 as uuidv4 } from "uuid";
import customFabric from "./customFabric";
import { jsPDF } from "jspdf";
import stickynoteBlack from "../assets/images/stickynote/black.svg";
import stickynoteBlueLight from "../assets/images/stickynote/blue-light.svg";
import stickynoteBlue from "../assets/images/stickynote/blue.svg";
import stickynoteGreenDark from "../assets/images/stickynote/green-dark.svg";
import stickynoteGreenLight from "../assets/images/stickynote/green-light.svg";
import stickynoteGreen from "../assets/images/stickynote/green.svg";
import stickynoteGrey from "../assets/images/stickynote/grey.svg";
import stickynoteHotPink from "../assets/images/stickynote/hot-pink.svg";
import stickynoteOceanGreen from "../assets/images/stickynote/ocean-green.svg";
import stickynoteOrange from "../assets/images/stickynote/orange.svg";
import stickynotePink from "../assets/images/stickynote/pink.svg";
import stickynotePurple from "../assets/images/stickynote/purple.svg";
import stickynoteRed from "../assets/images/stickynote/red.svg";
import stickynoteSkyBlue from "../assets/images/stickynote/sky-blue.svg";
import stickynoteYellow from "../assets/images/stickynote/yellow.svg";
import stickynoteYellowLight from "../assets/images/stickynote/yellow-light.svg";
import { eraser, brush, pencil } from "../assets/ico";

fabric = customFabric(fabric);

export const drawingTool = {
  ARROW: "Arrow",
  ERASER: "Eraser",
  LINE: "Line",
  CIRCLE: "Circle",
  RECTANGLE: "Rectangle",
  TRIANGLE: "Triangle",
  POLYGON: "Polygon",
  PENCIL: "Pencil",
  TEXT: "Text",
  HIGHLIGHTER: "Highlighter",
  HEART: "Heart",
  SINGLE_HEAD_ARROW: "ingle-head-arrow",
  DOUBLE_HEAD_ARROW: "double-head-arrow",
  CURVED_ARROW: "curved-arrow",
};

export const notesColor = {
  BLACK: "BLACK",
  BLUE_LIGHT: "BLUE_LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  GREEN_LIGHT: "GREEN_LIGHT",
  GREEN_DARK: "GREEN_DARK",
  GREY: "GREY",
  HOT_PINK: "HOT_PINK",
  OCEAN_GREEN: "OCEAN_GREEN",
  ORANGE: "ORANGE",
  PINK: "PINK",
  PURPLE: "PURPLE",
  RED: "RED",
  SKY_BLUE: "SKY_BLUE",
  YELLOW_LIGHT: "YELLOW_LIGHT",
  YELLOW: "YELLOW",
};

class FabricCanvas {
  constructor(canvasId, options, socket, setActiveShape, setHistory, cb) {
    this.tool = "";
    this.state = {};
    this.socket = socket;
    this.drawingColor = "#000000";
    this.drawingWidth = 10;
    this.arrowColor = "#000000";
    this.arrowWidth = 2;
    this.fillColor = "#f3f3f3";
    this.strokeColor = "#000000";
    this.shapeFill = "#000000";
    this.currentObject = null;
    this.HIGHLIGHTERRATIO = 3;
    this.PENCILRATIO = 0.2;
    this.currentHistoryState = -1;
    this.historyStates = [];
    this.setActiveShape = setActiveShape;
    this.setHistory = setHistory;
    this.callback = cb;
    this.canvas = new fabric.Canvas(canvasId, options);

    this.canvas.isDrawingMode = false;
    this.canvas.setDimensions({
      width: 1920,
      height: 1080,
    });

    socket.on("new_user_joined", (event, user) => {
      var json = this.canvas._historyNext();
      socket.emit("canvas", event, json, this.canvas._getCompleteHistory());
      cb("NEW_USER_JOIN", `${user.name} join the room`, user);
    });

    socket.on("on_canvas", (canvasData, history) => {
      if (canvasData) {
        this.canvas._setCompleteHistory(
          history.historyUndo,
          history.historyRedo
        );
        this.canvas.loadFromJSON(canvasData, () => {
          this.canvas.renderAll();
        });
      }
      this.socket.off("on_canvas");
      this.onHistorySave();
    });

    /**
     * Socket event
     */
    this.socket.on("object:added", (e) => {
      if (!this.getItemById(e.id)) {
        e.messageType = "broadcast";
        this.addObject(e);
      }
    });

    this.socket.on("on-link-receive", (dataArr) => {
      if (dataArr && dataArr.images && dataArr.images.length > 0) {
        let imageUrls = dataArr.images;
        imageUrls.map((url, index) => {
          let payload = {
            // left: 100,
            top: 100,
            index: index,
            // width: 600,
            // height: 900,
          };

          this.loadImage(url, payload);
          return true;
        });
      }
    });

    this.socket.on("notes:modified", (e) => {
      let { obj, id } = e;

      if (obj && obj.type === "group") {
        let object = this.getItemById(id);
        if (object) {
          this.canvas.remove(object);
        }
        fabric.util.enlivenObjects([obj], (objects) => {
          objects.forEach((fabricObject) => {
            fabricObject.set({ id: id, isOld: true });
            fabricObject.set({
              hasControls: false,
              hasBorders: false,
              lockMovementX: true,
              lockMovementY: true,
            });

            this.canvas.add(fabricObject);
            if (fabricObject.id && fabricObject.id.startsWith("sticky-notes")) {
              stickyNotesEvents.addStickyNoteSettings(
                fabricObject,
                this.canvas
              );
              stickyNotesEvents.addTextBoxSettings(
                fabricObject.getObjects()[1],
                fabricObject,
                this.canvas
              );
            }

            this.canvas.renderAll();
            this.canvas._historySaveAction({ target: fabricObject });
            this.onHistorySave();
          });
        });
      }
    });

    this.socket.on("object:modified", (e) => {
      let { obj, id } = e;

      this.canvas.getObjects().forEach((object) => {
        if (object.id === id) {
          if (
            obj?.type &&
            (obj.type === "Arrow" ||
              obj.type === "lineArrow" ||
              obj.type === "lineWithArrow" ||
              obj.type === "line")
          ) {
            let properties = this.moveLine(obj);
            obj = { ...obj, ...properties };
            object.set(properties);
            object.setCoords();
            this.canvas.renderAll();
          }
          object.set(obj);
          object.setCoords();
          this.canvas.renderAll();
          this.canvas._historySaveAction();
          this.onHistorySave();
        }
      });
    });

    this.socket.on("object:removed", (e) => {
      let object = this.getItemById(e.id);
      if (object) {
        this.canvas.remove(object);
      }
    });

    this.socket.on("object:created", (e) => {});

    this.socket.on("object:skewed", (e) => {});

    this.socket.on("undo", () => {
      this.canvas.undo();
      this.onHistorySave();
    });

    this.socket.on("redo", () => {
      this.canvas.redo();
      this.onHistorySave();
    });

    /**
     * Canvas Object event
     */
    this.canvas.on("object:modified", (event) => {
      if (event && event.messageType !== "broadcast") {
        this.socket.emit("object:modified", {
          obj: event.target,
          id: event.target.id,
        });
      }
      this.saveHistoryState();
      this.onHistorySave();
    });

    this.canvas.on("notes:modified", (event) => {
      if (event && event.messageType !== "broadcast") {
        this.socket.emit("notes:modified", {
          obj: event.target,
          id: event.target.id,
        });
      }
    });

    this.canvas.on("object:added", (event) => {
      if (!event.target.id && event.target.parentType) {
        let { target } = event;
        target.set({ id: this.generateId() });
        this.disableMoving(target);
        let obj = { obj: target, id: target.id };

        if (target.points) {
          obj.points = target.points;
        }
        if (target.parentType === "text") {
          this.canvas.fire("text:added", event);
        }

        this.socket.emit("object:added", obj);
        this.onHistorySave();
      } else if (
        event.target.id &&
        !event.target.id.startsWith("sticky-text")
      ) {
        if (!event.target.isOld) {
          this.onHistorySave();
        }
      }
    });

    this.canvas.on("path:created", (event) => {
      event.path.id = this.generateId();
      event.path.type = "path";
      event.path.set({ selectable: false });
      socket.emit("object:added", {
        id: event.path.id,
        typeModal: "brush",
        obj: event.path,
      });
      this.saveHistoryState();
      this.onHistorySave();
    });

    this.canvas.on("path:added", (event) => {
      // this.onHistorySave()
    });

    this.canvas.on("text:added", (event) => {
      this.onHistorySave();
    });

    this.canvas.on("object:skewed", (event) => {
      this.saveHistoryState();
    });
  }

  addObject(e) {
    let {
      obj,
      id,
      typeModal,
      parentType,
      messageType,
      points = [e.obj.x1, e.obj.y1, e.obj.x2, e.obj.y2],
    } = e;
    let { type } = obj;
    let object;
    switch (type) {
      case "circle":
        object = new fabric.Circle(obj);
        break;
      case "triangle":
        object = new fabric.Triangle(obj);
        break;
      case "rect":
        object = new fabric.Rect(obj);
        break;
      case "path":
        object = new fabric.Path(obj.path, obj);
        if (typeModal === "brush") {
          // this.canvas.fire("path:added", { object });
        }
        break;
      case "textbox":
        object = new fabric.Textbox("Text", obj);
        break;
      case "Arrow":
        object = new fabric.Arrow(points, obj);
        break;
      case "lineWithArrow":
        object = new fabric.LineWithArrow(points, obj);
        break;
      case "line":
        object = new fabric.Line(points, obj);
        break;
      case "lineArrow":
        object = new fabric.LineArrow(points, obj);
        break;
      case "group":
        fabric.util.enlivenObjects([obj], (objects) => {
          objects.forEach((fabricObject) => {
            fabricObject.set({ id: id });

            this.canvas.add(fabricObject);

            if (fabricObject.id && fabricObject.id.startsWith("sticky-notes")) {
              stickyNotesEvents.addStickyNoteSettings(
                fabricObject,
                this.canvas
              );
              stickyNotesEvents.addTextBoxSettings(
                fabricObject.getObjects()[1],
                fabricObject,
                this.canvas
              );
            }

            this.canvas.fire("notes:added", { target: fabricObject });
            this.onHistorySave();
          });
        });
        break;
      default:
        object = false;
    }

    if (object) {
      this.addToCanvas(object, id, parentType, messageType);
      if (typeModal === "brush") {
        this.canvas._historySaveAction();
      }
      if (obj.type === "textbox") {
        this.canvas.fire("text:added", { obj });
      }
      this.onHistorySave();
    }
  }

  addToCanvas(obj, id, parentType, messageType) {
    obj.set({ id: id });
    if (parentType) {
      obj.set({ parentType: parentType });
    }
    if (messageType) {
      obj.set({ messageType: messageType });
    }
    this.disableMoving(obj);
    this.canvas.add(obj);
  }

  disableMoving(obj) {
    if (obj) {
      // obj.set({ lockMovementX: true, lockMovementY: true, hasControls: false });
    }
  }

  loadImage(imageData, payload) {
    new fabric.Image.fromURL(imageData.imagePath, (img) => {
      payload.left = 10 + img.left + payload.index * img.width;
      img.set({
        ...payload,
        id: imageData.id,
        hasBorders: false,
      });
      this.canvas.fire("image:added");
      this.disableMoving(img);
      this.canvas.add(img);
    });
  }

  moveLine(line) {
    let properties = {};
    let oldCenterX = (line.x1 + line.x2) / 2,
      oldCenterY = (line.y1 + line.y2) / 2,
      deltaX = line.left - oldCenterX,
      deltaY = line.top - oldCenterY;

    properties.x1 = line.x1 + deltaX;
    properties.y1 = line.y1 + deltaY;
    properties.x2 = line.x2 + deltaX;
    properties.y2 = line.y2 + deltaY;
    properties.left = (properties.x1 + properties.x2) / 2;
    properties.top = (properties.y1 + properties.y2) / 2;
    return properties;
  }

  getCanvas() {
    return this.canvas;
  }

  getTool() {
    return this.tool;
  }

  setTool(tool) {
    this.tool = tool;
  }

  disableDrawing() {
    this.clearHandlers();
    this.canvas.isDrawingMode = false;
  }

  freeEraser(width = 10) {
    this.clearHandlers();
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingCursor = `url(${eraser})0 100, auto`;
    this.canvas.freeDrawingBrush.width = parseInt(width);
    this.canvas.freeDrawingBrush.id = "eraser";
    this.canvas.freeDrawingBrush.color = "#F2F3F2";
  }

  selectTool() {
    this.clearHandlers();
    this.canvas.isDrawingMode = false;
  }

  generateId() {
    return uuidv4();
  }

  clearHandlers() {
    const canvas = this.canvas;
    canvas.isDrawingMode = false;
    canvas.off("mouse:down");
    canvas.off("mouse:move");
    canvas.off("mouse:up");
    canvas.off("object:selected");
    this.canvas.freeDrawingCursor = "crosshair";
  }

  loadHistoryStateByIndex(index) {
    let canvas = this.canvas;
    try {
      if (canvas) {
        canvas.loadFromJSON(
          this.historyStates[index],
          canvas.renderAll.bind(canvas),
          (jsonObject, fabricObject) => {
            fabricObject.id = jsonObject.id;
            if (fabricObject.id && fabricObject.id.startsWith("sticky-notes")) {
              stickyNotesEvents.addStickyNoteSettings(fabricObject, canvas);
              stickyNotesEvents.addTextBoxSettings(
                fabricObject.getObjects()[1],
                fabricObject,
                canvas
              );
            }
          }
        );

        canvas.forEachObject((o) => {
          o.selectable = true;
        });
        canvas.selectable = true;
        canvas.renderAll.bind(canvas);
      }
    } catch (error) {
      console.log("error on loadHistoryStateByIndex =>", error);
    }
  }

  saveHistoryState() {
    let canvas = this.canvas;
    if (this.currentHistoryState !== -1) {
      this.historyStates = this.historyStates.slice(
        0,
        this.currentHistoryState + 1
      );
    }
    this.historyStates.push(canvas.toJSON(["id"]));
    this.currentHistoryState = this.historyStates.length - 1;
  }

  undo() {
    this.canvas.undo();
    this.socket.emit("undo");
    this.onHistorySave();
  }

  redo() {
    this.canvas.redo();
    this.socket.emit("redo");
    this.onHistorySave();
  }

  getItemById(id) {
    let objects = this.canvas.getObjects();
    return objects.find((d) => d.id === id);
  }

  eraseObject() {
    this.disableDrawing();
    const canvas = this.canvas;
    const object = canvas.getActiveObject();
    if (object) {
      canvas.remove(object);
      this.socket.emit("object:removed", {
        id: object.toObject(["id"])?.id,
      });
    }
    canvas.on("mouse:up", () => {
      const obj = canvas.getActiveObject();
      if (obj) {
        canvas.remove(obj);
        this.socket.emit("object:removed", {
          id: obj.toObject(["id"])?.id,
        });
      }
    });
  }

  setDrawingBrushWidth(width) {
    if (this.getTool() === drawingTool.HIGHLIGHTER) {
      this.drawingWidth = width;
      this.canvas.freeDrawingBrush.width =
        parseInt(width) * this.HIGHLIGHTERRATIO;
    } else {
      this.drawingWidth = width;
      this.canvas.freeDrawingBrush.width = parseInt(width) * this.PENCILRATIO;
    }
  }

  setDrawingBrushColor(color) {
    this.drawingColor = color;
    if (this.getTool() === drawingTool.HIGHLIGHTER) {
      this.canvas.freeDrawingBrush.color = this.hexToRgbA(`${color}`);
    } else {
      this.canvas.freeDrawingBrush.color = color;
    }
  }

  setArrowWidth(width) {
    this.arrowWidth = parseInt(width);
  }

  setArrowColor(color) {
    this.arrowColor = color;
  }

  hexToRgbA(hex) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",.5)"
      );
    }
    throw new Error("Bad Hex");
  }

  freeDrawing() {
    this.clearHandlers();
    const canvas = this.canvas;
    canvas.isDrawingMode = true;

    if (this.tool === drawingTool.HIGHLIGHTER) {
      canvas.freeDrawingBrush.width =
        parseInt(this.drawingWidth) * this.HIGHLIGHTERRATIO;
      canvas.freeDrawingBrush.color = this.hexToRgbA(`${this.drawingColor}`);
      this.canvas.freeDrawingCursor = `url(${brush})0 80, auto`;
    } else {
      canvas.freeDrawingBrush.width =
        parseInt(this.drawingWidth) * this.PENCILRATIO;
      canvas.freeDrawingBrush.color = this.drawingColor;
      this.canvas.freeDrawingCursor = `url(${pencil})0 80, auto`;
    }
  }

  addText() {
    let x1, y1, x2, y2;
    this.clearHandlers();
    const canvas = this.canvas;

    canvas.on("mouse:down", (o) => {
      const pointer = canvas.getPointer(o.e);
      x1 = pointer.x;
      y1 = pointer.y;
    });

    canvas.on("mouse:up", (o) => {
      const pointer = canvas.getPointer(o.e);
      x2 = pointer.x;
      y2 = pointer.y;

      const text = new fabric.Textbox("Enter Text", {
        left: x1,
        top: y1,
        width: x2 - x1,
        height: y2 - y1,
        //fill: canvas.freeDrawingBrush.color,
      });
      text.set({ parentType: "text" });
      // text.set({ id: this.generateId() });
      // this.socket.emit("object:added", { obj: text, id: text.id });
      canvas.add(text);
      this.setActiveShape("select");
      this.clearHandlers();
    });
  }

  drawSingleHeadArrow() {
    let isDown, arrow;
    this.clearHandlers();
    const canvas = this.canvas;
    let properties = {};

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      const points = [pointer.x, pointer.y, pointer.x, pointer.y];
      arrow = new fabric.Arrow(points, {
        strokeWidth: this.arrowWidth,
        stroke: this.arrowColor,
        fill: this.arrowColor,
        originX: "center",
        originY: "center",
      });
      arrow.set({ parentType: "lines" });
      arrow.set({ points: points });
      canvas.add(arrow);
    });

    canvas.on("mouse:move", (o) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(o.e);
      properties.x2 = pointer.x;
      properties.y2 = pointer.y;
      arrow.set(properties);
      canvas.renderAll();
    });

    canvas.on("mouse:up", (o) => {
      isDown = false;
      const pointer = canvas.getPointer(o.e);
      properties.x2 = pointer.x;
      properties.y2 = pointer.y;
      this.onMouseUpEvent(arrow, properties);
      this.drawSingleHeadArrow();
    });
  }

  drawCurvedArrow() {
    let isDown, lineWithArrow;
    this.clearHandlers();
    const canvas = this.canvas;
    let properties = {};

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      const points = [pointer.x, pointer.y, pointer.x, pointer.y];
      lineWithArrow = new fabric.LineWithArrow(points, {
        strokeWidth: this.arrowWidth,
        stroke: this.arrowColor,
        fill: this.arrowColor,
        originX: "center",
        originY: "center",
      });
      lineWithArrow.set({ parentType: "lines" });
      lineWithArrow.set({ points: points });
      canvas.add(lineWithArrow);
    });

    canvas.on("mouse:move", (o) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(o.e);
      properties.x2 = pointer.x;
      properties.y2 = pointer.y;
      lineWithArrow.set(properties);
      canvas.renderAll();
    });

    canvas.on("mouse:up", (o) => {
      isDown = false;
      const pointer = canvas.getPointer(o.e);
      properties.x2 = pointer.x;
      properties.y2 = pointer.y;
      this.onMouseUpEvent(lineWithArrow, properties);
      this.drawCurvedArrow();
    });
  }

  drawDoubleHeadArrow() {
    let isDown, lineArrow;
    this.clearHandlers();
    const canvas = this.canvas;
    let properties = {};

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      const points = [pointer.x, pointer.y, pointer.x, pointer.y];
      lineArrow = new fabric.LineArrow(points, {
        strokeWidth: this.arrowWidth,
        stroke: this.arrowColor,
        fill: this.arrowColor,
        originX: "center",
        originY: "center",
      });
      lineArrow.set({ parentType: "lines" });
      lineArrow.set({ points: points });
      canvas.add(lineArrow);
    });

    canvas.on("mouse:move", (o) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(o.e);
      properties.x2 = pointer.x;
      properties.y2 = pointer.y;
      lineArrow.set(properties);
      canvas.renderAll();
    });

    canvas.on("mouse:up", (o) => {
      isDown = false;
      const pointer = canvas.getPointer(o.e);
      properties.x2 = pointer.x;
      properties.y2 = pointer.y;
      this.onMouseUpEvent(lineArrow, properties);
      this.drawDoubleHeadArrow();
    });
  }

  enableSelection(canvas, selectionStatus) {
    canvas._objects.map((item, index) => {
      item.set({ selectable: selectionStatus });
      return true;
    });
    canvas.selection = selectionStatus;
  }

  drawHeart(fill) {
    let heart, isDown, origX, origY;
    this.clearHandlers();
    const canvas = this.canvas;
    let properties = {};

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);

      origX = pointer.x;
      origY = pointer.y;
      let width = Math.abs(origX - pointer.x) / 4;
      let scale = width / 100;
      let pathCord =
        "M 272.70141,238.71731 \
      C 206.46141,238.71731 152.70146,292.4773 152.70146,358.71731  \
      C 152.70146,493.47282 288.63461,528.80461 381.26391,662.02535 \
      C 468.83815,529.62199 609.82641,489.17075 609.82641,358.71731 \
      C 609.82641,292.47731 556.06651,238.7173 489.82641,238.71731  \
      C 441.77851,238.71731 400.42481,267.08774 381.26391,307.90481 \
      C 362.10311,267.08773 320.74941,238.7173 272.70141,238.71731  \
      z ";

      heart = new fabric.Path(pathCord, {
        left: origX,
        top: origY,
        scaleX: scale,
        scaleY: scale,
        fill: fill === "00" ? "rgba(0,0,0,0)" : this.shapeFill,
        stroke: this.strokeColor,
        strokeWidth: 2,
      });
      heart.set({ parentType: "shapes" });
      canvas.add(heart);
    });

    canvas.on("mouse:move", (o) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(o.e);
      if (origX > pointer.x) {
        properties.left = Math.abs(pointer.x);
      }
      if (origY > pointer.y) {
        properties.top = Math.abs(pointer.y);
      }
      let width = Math.abs(origX - pointer.x) / 4;
      let scale = width / 100;
      properties.scaleX = scale;
      properties.scaleY = scale;
      heart.set(properties);
      canvas.renderAll();
    });

    canvas.on("mouse:up", (o) => {
      isDown = false;
      const pointer = canvas.getPointer(o.e);
      if (origX > pointer.x) {
        properties.left = Math.abs(pointer.x);
      }
      if (origY > pointer.y) {
        properties.top = Math.abs(pointer.y);
      }
      let width = Math.abs(origX - pointer.x) / 4;
      let scale = width / 100;
      properties.scaleX = scale;
      properties.scaleY = scale;
      this.onMouseUpEvent(heart, properties);
      this.drawHeart(fill);
    });
  }

  changeShapeFill(color) {
    let activeShape = this.canvas.getActiveObject();
    this.shapeFill = color;

    if (activeShape && activeShape?.parentType === "shapes") {
      activeShape.set({ fill: this.shapeFill });
      this.canvas.fire("filled:modified", { Object: activeShape });
      this.socket.emit("object:modified", {
        obj: { fill: this.shapeFill },
        id: activeShape.id,
      });
      this.canvas.requestRenderAll();
      return true;
    } else {
      return false;
    }
  }

  getActiveShapeFill(shapeList = [], defaultColor = "#ffffff") {
    let activeShape = this.canvas.getActiveObject();
    if (activeShape && shapeList.includes(activeShape?.type)) {
      return activeShape?.fill;
    } else {
      return defaultColor;
    }
  }

  drawCircle(fill) {
    let circle, isDown, origX;
    this.clearHandlers();
    const canvas = this.canvas;
    let properties = {};

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      origX = pointer.x;
      circle = new fabric.Circle({
        left: pointer.x,
        top: pointer.y,
        radius: 1,
        fill: fill === "00" ? "rgba(0,0,0,0)" : this.shapeFill,
        stroke: this.strokeColor,
        strokeWidth: 1,
        originX: "center",
        originY: "center",
      });
      circle.set({ parentType: "shapes" });
      // circle.set({ id: this.generateId() });
      // this.socket.emit("object:added", { obj: circle, id: circle.id, parentType: "shapes" });
      canvas.add(circle);
    });

    canvas.on("mouse:move", (o) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(o.e);
      properties = { radius: Math.abs(origX - pointer.x) };
      circle.set(properties);
      canvas.renderAll();
    });

    canvas.on("mouse:up", (o) => {
      isDown = false;
      const pointer = canvas.getPointer(o.e);
      properties = { radius: Math.abs(origX - pointer.x) };
      this.onMouseUpEvent(circle, properties);
      this.drawCircle(fill);
    });
  }

  drawTriangle(fill) {
    let triangle, isDown, origX, origY;
    this.clearHandlers();
    const canvas = this.canvas;
    let properties = {};

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      origX = pointer.x;
      origY = pointer.y;
      triangle = new fabric.Triangle({
        left: pointer.x,
        top: pointer.y,
        width: pointer.x - origX,
        height: pointer.y - origY,
        fill: fill === "00" ? "rgba(0,0,0,0)" : this.shapeFill,
        stroke: this.strokeColor,
        strokeWidth: 1,
      });
      triangle.set({ parentType: "shapes" });
      // triangle.set({ id: this.generateId() });
      // this.socket.emit("object:added", { obj: triangle, id: triangle.id, parentType: "shapes" });
      canvas.add(triangle);
    });

    canvas.on("mouse:move", (o) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(o.e);
      if (origX > pointer.x) {
        triangle.set({
          left: Math.abs(pointer.x),
        });
        properties.left = Math.abs(pointer.x);
      }
      if (origY > pointer.y) {
        triangle.set({
          top: Math.abs(pointer.y),
        });
        properties.top = Math.abs(pointer.y);
      }
      triangle.set({
        width: Math.abs(origX - pointer.x),
      });
      triangle.set({
        height: Math.abs(origY - pointer.y),
      });
      properties.width = Math.abs(origX - pointer.x);
      properties.height = Math.abs(origY - pointer.y);
      canvas.renderAll();
    });

    canvas.on("mouse:up", (o) => {
      isDown = false;
      const pointer = canvas.getPointer(o.e);
      if (origX > pointer.x) {
        triangle.set({
          left: Math.abs(pointer.x),
        });
        properties.left = Math.abs(pointer.x);
      }
      if (origY > pointer.y) {
        triangle.set({
          top: Math.abs(pointer.y),
        });
        properties.top = Math.abs(pointer.y);
      }
      triangle.set({
        width: Math.abs(origX - pointer.x),
      });
      triangle.set({
        height: Math.abs(origY - pointer.y),
      });
      properties.width = Math.abs(origX - pointer.x);
      properties.height = Math.abs(origY - pointer.y);
      this.onMouseUpEvent(triangle, properties);
      this.drawTriangle(fill);
    });
  }

  drawLine() {
    let isDown, line;
    this.clearHandlers();
    const canvas = this.canvas;
    let properties = {};

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      const points = [pointer.x, pointer.y, pointer.x, pointer.y];
      line = new fabric.Line(points, {
        strokeWidth: this.arrowWidth,
        stroke: this.arrowColor,
        fill: this.arrowColor,
        originX: "center",
        originY: "center",
      });
      line.set({ parentType: "lines" });
      line.set({ points: points });
      canvas.add(line);
    });

    canvas.on("mouse:move", (o) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(o.e);
      properties.x2 = pointer.x;
      properties.y2 = pointer.y;
      line.set(properties);
      canvas.renderAll();
    });

    canvas.on("mouse:up", (o) => {
      isDown = false;
      const pointer = canvas.getPointer(o.e);
      properties.x2 = pointer.x;
      properties.y2 = pointer.y;
      this.onMouseUpEvent(line, properties);
      this.drawLine();
    });
  }

  drawPolygon(edge = 5, fill) {
    let path, isDown, origX, origY;
    this.clearHandlers();
    const canvas = this.canvas;
    let properties = {};

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      origX = pointer.x;
      origY = pointer.y;
      let width = Math.abs(origX - pointer.x) / 2;
      let scale = width / 100;
      let pathCord = "";
      let angle = 0;
      if (edge === 6) {
        pathCord = "M850 75 958 137.5 958 262.5 850 325 742 262.6 742 137.5Z";
        angle = 90;
      } else {
        pathCord = "M150 20 200 60 180 110 120 110 100 60Z";
      }
      path = new fabric.Path(pathCord, {
        left: origX,
        top: origY,
        scaleX: scale,
        scaleY: scale,
        fill: fill === "00" ? "rgba(0,0,0,0)" : this.shapeFill,
        stroke: this.strokeColor,
        strokeWidth: 2,
        angle,
      });
      path.set({ parentType: "shapes" });
      canvas.add(path);
    });

    canvas.on("mouse:move", (o) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(o.e);

      if (origX > pointer.x) {
        properties.left = Math.abs(pointer.x);
      }
      if (origY > pointer.y) {
        properties.top = Math.abs(pointer.y);
      }
      let width = Math.abs(origX - pointer.x) / 2;
      let scale = width / 100;
      properties.scaleX = scale;
      properties.scaleY = scale;
      properties.strokeWidth = 1;
      path.set(properties);

      canvas.renderAll();
    });

    canvas.on("mouse:up", (o) => {
      isDown = false;
      const pointer = canvas.getPointer(o.e);

      if (origX > pointer.x) {
        properties.left = Math.abs(pointer.x);
      }
      if (origY > pointer.y) {
        properties.top = Math.abs(pointer.y);
      }
      let width = Math.abs(origX - pointer.x) / 2;
      let scale = width / 100;
      properties.scaleX = scale;
      properties.scaleY = scale;
      properties.strokeWidth = 1;
      this.onMouseUpEvent(path, properties);
      this.drawPolygon(edge, fill);
    });
  }

  drawRoundedRectangle(fill) {
    let rect, isDown, origX, origY;
    this.clearHandlers();
    const canvas = this.canvas;
    let properties = {};

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      origX = pointer.x;
      origY = pointer.y;
      rect = new fabric.Rect({
        left: pointer.x,
        top: pointer.y,
        width: pointer.x - origX,
        height: pointer.y - origY,
        fill: fill === "00" ? "rgba(0,0,0,0)" : this.shapeFill,
        stroke: this.strokeColor,
        strokeWidth: 1,
        rx: 10,
        ry: 10,
      });
      rect.set({ parentType: "shapes" });
      // rect.set({ id: this.generateId() });
      // this.socket.emit("object:added", { obj: rect, id: rect.id, parentType: "shapes" });
      canvas.add(rect);
    });

    canvas.on("mouse:move", (o) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(o.e);
      if (origX > pointer.x) {
        properties.left = Math.abs(pointer.x);
      }
      if (origY > pointer.y) {
        properties.top = Math.abs(pointer.y);
      }
      properties.width = Math.abs(origX - pointer.x);
      properties.height = Math.abs(origY - pointer.y);
      rect.set(properties);
      canvas.renderAll();
    });

    canvas.on("mouse:up", (o) => {
      isDown = false;
      const pointer = canvas.getPointer(o.e);
      if (origX > pointer.x) {
        properties.left = Math.abs(pointer.x);
      }
      if (origY > pointer.y) {
        properties.top = Math.abs(pointer.y);
      }
      properties.width = Math.abs(origX - pointer.x);
      properties.height = Math.abs(origY - pointer.y);
      this.onMouseUpEvent(rect, properties);
      this.drawRoundedRectangle(fill);
    });
  }

  drawSquare(fill) {
    let square, isDown, origX, origY;
    this.clearHandlers();
    const canvas = this.canvas;
    let properties = {};

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      origX = pointer.x;
      origY = pointer.y;
      square = new fabric.Rect({
        left: pointer.x,
        top: pointer.y,
        width: 0,
        height: 0,
        strokeWidth: 1,
        fill: fill === "00" ? "rgba(0,0,0,0)" : this.shapeFill,
        stroke: this.strokeColor,
      });
      square.set({ parentType: "shapes" });
      // square.set({ id: this.generateId() });
      // this.socket.emit("object:added", { obj: square, id: square.id, parentType: "shapes" });
      canvas.add(square);
    });

    canvas.on("mouse:move", (o) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(o.e);
      const width = Math.abs(origX - pointer.x),
        height = Math.abs(origY - pointer.y);

      properties.width = width;
      properties.height = height;
      square.set(properties);
      canvas.renderAll();
    });

    canvas.on("mouse:up", (o) => {
      isDown = false;
      const pointer = canvas.getPointer(o.e);
      const width = Math.abs(origX - pointer.x),
        height = Math.abs(origY - pointer.y);

      properties.width = width;
      properties.height = height;
      this.onMouseUpEvent(square, properties);
      this.drawSquare(fill);
    });
  }

  drawRhomeBus(fill) {
    let square, isDown, origX, origY;
    this.clearHandlers();
    const canvas = this.canvas;
    let properties = {};

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      origX = pointer.x;
      origY = pointer.y;
      square = new fabric.Rect({
        left: pointer.x,
        top: pointer.y,
        width: 0,
        height: 0,
        strokeWidth: 1,
        fill: fill === "00" ? "rgba(0,0,0,0)" : this.shapeFill,
        stroke: this.strokeColor,
        angle: 45,
      });
      square.set({ parentType: "shapes" });
      // square.set({ id: this.generateId() });
      // this.socket.emit("object:added", { obj: square, id: square.id, parentType: "shapes" });
      canvas.add(square);
    });

    canvas.on("mouse:move", (o) => {
      if (!isDown) return;
      const pointer = canvas.getPointer(o.e);
      const width = Math.abs(origX - pointer.x);

      properties.width = width;
      properties.height = width;
      square.set(properties);
      canvas.renderAll();
    });

    canvas.on("mouse:up", (o) => {
      isDown = false;
      const pointer = canvas.getPointer(o.e);
      const width = Math.abs(origX - pointer.x);
      properties.width = width;
      properties.height = width;

      this.onMouseUpEvent(square, properties);
      this.drawRhomeBus(fill);
    });
  }

  getStickyNote(color) {
    let stickynote = "";
    switch (String(color)) {
      case notesColor.BLACK:
        stickynote = stickynoteBlack;
        break;
      case notesColor.RED:
        stickynote = stickynoteRed;
        break;
      case notesColor.YELLOW:
        stickynote = stickynoteYellow;
        break;
      case notesColor.GREEN:
        stickynote = stickynoteGreen;
        break;
      case notesColor.BLUE:
        stickynote = stickynoteBlue;
        break;
      case notesColor.BLUE_LIGHT:
        stickynote = stickynoteBlueLight;
        break;
      case notesColor.GREEN_LIGHT:
        stickynote = stickynoteGreenLight;
        break;
      case notesColor.GREEN_DARK:
        stickynote = stickynoteGreenDark;
        break;
      case notesColor.GREY:
        stickynote = stickynoteGrey;
        break;
      case notesColor.HOT_PINK:
        stickynote = stickynoteHotPink;
        break;
      case notesColor.OCEAN_GREEN:
        stickynote = stickynoteOceanGreen;
        break;
      case notesColor.ORANGE:
        stickynote = stickynoteOrange;
        break;
      case notesColor.PINK:
        stickynote = stickynotePink;
        break;
      case notesColor.PURPLE:
        stickynote = stickynotePurple;
        break;
      case notesColor.SKY_BLUE:
        stickynote = stickynoteSkyBlue;
        break;
      case notesColor.YELLOW_LIGHT:
        stickynote = stickynoteYellowLight;
        break;
      default:
        stickynote = stickynoteYellow;
        break;
    }
    return stickynote;
  }

  createNotes(color) {
    let stickyNotes, isDown, origX, origY;
    this.clearHandlers();
    const canvas = this.canvas;

    canvas.on("mouse:down", (o) => {
      canvas.selection = false;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      origX = pointer.x;
      origY = pointer.y;

      fabric.loadSVGFromURL(this.getStickyNote(color), (objects, options) => {
        const SVGObject = fabric.util.groupSVGElements(objects, options);
        SVGObject.id = `sticky-svg-${this.generateId()}`;

        const text = new fabric.Textbox("Text", {
          left: origX,
          top: origY,
          width: SVGObject.width - 20,
          fontSize: 166.6,
          lockScalingY: true,
          fill: "rgb(0,0,0)",
          fontFamily: "Arial",
          id: `sticky-text-${this.generateId()}`,
          tempObject: true,
        });

        if (color === notesColor.BLACK) {
          text.set({ fill: "rgb(255,255,255)" });
        }

        SVGObject.set({
          left: text.left - 10,
          top: text.top - 10,
        });

        const shadow = new fabric.Shadow({
          color: "#b8b7b6",
          blur: 20,
          offsetX: 0,
          offsetY: 20,
        });

        stickyNotes = new fabric.Group([SVGObject, text], {
          scaleX: 0.5,
          scaleY: 0.5,
          selectable: true,
          evented: true,
          id: `sticky-notes-${this.generateId()}`,
          shadow,
        });

        stickyNotesEvents.addStickyNoteSettings(stickyNotes, this.canvas);
        stickyNotesEvents.addTextBoxSettings(text, stickyNotes, this.canvas);

        this.canvas.add(stickyNotes).setActiveObject(stickyNotes);
        this.canvas.renderAll();
        this.clearHandlers();
        canvas.on("mouse:move", (o) => {
          if (!isDown) return;
          const pointer = canvas.getPointer(o.e);

          if (origX > pointer.x) {
            stickyNotes.set({
              left: Math.abs(pointer.x),
            });
          }
          if (origY > pointer.y) {
            stickyNotes.set({
              top: Math.abs(pointer.y),
            });
          }
          let width = Math.abs(origX - pointer.x) / 4;
          let scale = width / 100;
          stickyNotes.set({ scaleX: scale, scaleY: scale });

          this.socket.emit("object:modified", {
            id: stickyNotes.id,
          });
          canvas.renderAll();
        });

        canvas.on("mouse:up", (o) => {
          isDown = false;
          stickyNotes.setCoords();
          this.clearHandlers();
          stickyNotes.set({
            hasControls: false,
            hasBorders: false,
            lockMovementX: true,
            lockMovementY: true,
          });
          this.socket.emit("object:added", {
            obj: stickyNotes,
            id: stickyNotes.id,
          });
          this.canvas.fire("notes:added", { target: stickyNotes });
          this.onHistorySave();
        });
      });
    });
  }

  exportAsImage() {
    let link = document.createElement("a");
    link.href = this.canvas.toDataURL("jpeg");
    link.download = `whiteboard_${this.generateId()}.jpeg`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  exportAsPDF() {
    const canvasAsImageB64 = this.canvas.toDataURL();
    const pdfDoc = jsPDF({
      orientation: "l",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: true,
      floatPrecision: 16,
    });

    const widthToHeightRatio = this.canvas.getHeight() / this.canvas.getWidth();
    const xOffset = 20;
    const yOffset = 25;
    const width = 297 - 2 * xOffset;
    const height = width * widthToHeightRatio;
    pdfDoc.addImage(canvasAsImageB64, "PNG", xOffset, yOffset, width, height);
    pdfDoc.save(`whiteboard_${this.generateId()}.pdf`);
  }

  onMouseUpEvent(object, properties) {
    this.socket.emit("object:modified", {
      id: object.id,
      obj: properties,
    });
    object.setCoords();
    this.canvas.off("mouse:down");
    this.canvas.off("mouse:move");
    this.canvas.off("mouse:up");
    this.disableMoving(object);

    this.canvas._historySaveAction();
    this.onHistorySave();
  }

  onHistorySave() {
    let completeHistory = this.canvas._getCompleteHistory();
    let undo = completeHistory.historyUndo;
    let redo = completeHistory.historyRedo;
    let history = {};

    if (undo && undo.length > 0) {
      history.undo = true;
    } else {
      history.undo = false;
    }

    if (redo && redo.length > 0) {
      history.redo = true;
    } else {
      history.redo = false;
    }
    this.setHistory(history);
  }
}

export default FabricCanvas;
