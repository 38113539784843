import { useEffect, useRef, useState } from "react";
import { drawingTool } from "../../../lib/canvas";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const childrenShapes = ["pencil", "brush"];
// const colors = ["#000000", "#FFCA2E", "#FB671F", "#0920E8", "#37BDFC", "other"];

function Draw({ canvas, isActive, setActiveShape }) {
  const [drawingWidth, setDrawingWidth] = useState(2);
  const [color, setColor] = useState("#000000");
  const [activeColor, setActiveColor] = useState("#000000");

  function changeColor(event, colorValue) {
    const color = colorValue ? colorValue : event.target.value;
    setActiveColor(color);
    setColor(() => color);
    canvas.setDrawingBrushColor(color);
  }

  function startDrawing(tool) {
    if (tool === drawingTool.PENCIL) {
      setActiveShape("pencil");
      setDrawingWidth(() => 2);
    } else if (tool === drawingTool.HIGHLIGHTER) {
      setActiveShape("brush");
      setDrawingWidth(() => 8);
    }
    canvas.setTool(tool);
    canvas.freeDrawing();
  }

  function changeWidth(event) {
    const value = event.target.value;
    canvas.setDrawingBrushWidth(value);
    setDrawingWidth(() => value);
  }

  function setActiveTool(e) {
    if (!childrenShapes.includes(isActive)) {
      startDrawing(drawingTool.PENCIL);
    }
  }

  return (
    <div>
      <Link
        to="#"
        className={`${childrenShapes.includes(isActive) ? "active" : ""}`}
        id="dropdownMenuLink"
        data-toggle="dropdown"
        onFocus={setActiveTool}
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="icon-pen" data-tip="Draw" data-for="drawOneTooltip"></i>
        <ReactTooltip
          className="commonTooltip"
          id="drawOneTooltip"
          place="top"
          type="dark"
          effect="solid"
        />
      </Link>
      <div
        className="dropdown-menu arrowDropdown"
        aria-labelledby="dropdownMenuLink"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="arrowList">
          <Link
            to="#"
            className={`dropdown-item ${isActive === "pencil" ? "active" : ""}`}
            onClick={() => {
              startDrawing(drawingTool.PENCIL);
            }}
          >
            <i className="icon-pen" data-tip="Draw" data-for="drawTooltip"></i>
            <ReactTooltip
              className="commonTooltip"
              id="drawTooltip"
              place="top"
              type="dark"
              effect="solid"
            />
          </Link>
          <Link
            to="#"
            className={`dropdown-item ${isActive === "brush" ? "active" : ""}`}
            onClick={() => {
              startDrawing(drawingTool.HIGHLIGHTER);
            }}
          >
            <i
              className="icon-highliter"
              data-tip="Marker"
              data-for="markerTooltip"
            ></i>
            <ReactTooltip
              className="commonTooltip"
              id="markerTooltip"
              place="top"
              type="dark"
              effect="solid"
            />
          </Link>
        </div>
        <ul className="list-inline colorList">
          <li className="list-inline-item">
            <Link
              to="#"
              className={`selectedColor ${
                activeColor === "#000000" ? "active" : ""
              }`}
              style={{ backgroundColor: "#000000" }}
              onClick={(event) => changeColor(event, "#000000")}
            ></Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`selectedColor ${
                activeColor === "#FFCA2E" ? "active" : ""
              }`}
              style={{ backgroundColor: "#FFCA2E" }}
              onClick={(event) => changeColor(event, "#FFCA2E")}
            ></Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`selectedColor ${
                activeColor === "#FB671F" ? "active" : ""
              }`}
              style={{ backgroundColor: "#FB671F" }}
              onClick={(event) => changeColor(event, "#FB671F")}
            ></Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`selectedColor ${
                activeColor === "#0920E8" ? "active" : ""
              }`}
              style={{ backgroundColor: "#0920E8" }}
              onClick={(event) => changeColor(event, "#0920E8")}
            ></Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="#"
              className={`selectedColor ${
                activeColor === "#37BDFC" ? "active" : ""
              }`}
              style={{ backgroundColor: "#37BDFC" }}
              onClick={(event) => changeColor(event, "#37BDFC")}
            ></Link>
          </li>
          <li className="list-inline-item">
            <label className="addColor">
              <input
                type="color"
                id="addColor"
                name=""
                onChange={changeColor}
                value={color}
              />
              <i className="icon-addcircle-outline"></i>
            </label>
          </li>
        </ul>
        <div className="range">
          <span>Size</span>
          <div className="slidecontainer">
            <input
              type="range"
              min="1"
              max="10"
              value={drawingWidth}
              className="slider"
              onChange={changeWidth}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Draw;
