import { Link } from "react-router-dom";
import { useState } from "react";
import ReactTooltip from 'react-tooltip';

const childrenShapes = ["line", "lineWithSingleArrow", "lineWithDoubleArrow", "lineWithCurvedArrow"];

function Arrow({ canvas, isActive, setActiveShape }) {

  const [arrowWidth, setArrowWidth] = useState(1);
  // const [color, setColor] = useState("#000000");

  // function changeColor(event, colorValue) {
  //   const color = colorValue ? colorValue : event.target.value;
  //   setColor(() => color);
  //   canvas.setArrowColor(color);
  // }

  function changeWidth(event) {
    const value = event.target.value;
    canvas.setArrowWidth(value);
    setArrowWidth(() => value);
  }

  function drawLine(e) {
    setActiveShape("line")
    canvas.drawLine();
  }

  function drawSingleHeadArrow(e) {
    setActiveShape("lineWithSingleArrow")
    canvas.drawSingleHeadArrow();
  }

  function drawDoubleHeadArrow(e) {
    setActiveShape("lineWithDoubleArrow")
    canvas.drawDoubleHeadArrow();
  }

  function drawCurvedArrow(e) {
    setActiveShape("lineWithCurvedArrow")
    canvas.drawCurvedArrow();
  }

  function setActiveTool(e){
    if(!childrenShapes.includes(isActive)){
      drawLine();
    }
  }

  return (
    <div>
      <Link
        to="#"
        onFocus={setActiveTool}
        className={`${childrenShapes.includes(isActive) ? "active" : ""}`}
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i
          className="icon-a2"
          data-tip="Arrow" data-for="arrowTooltip"
        ></i>
        <ReactTooltip className="commonTooltip" id="arrowTooltip" place="top" type="dark" effect='solid' />
      </Link>
      <div
        className="dropdown-menu arrowDropdown"
        aria-labelledby="dropdownMenuLink"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="arrowList">
          <Link className={`dropdown-item ${isActive === "lineWithCurvedArrow" ? "active" : ""}`} to="#" onClick={drawCurvedArrow}>
            <i
              className="icon-a2"
              data-tip="Arrow" data-for="arrowTwoTooltip"
            ></i>
            <ReactTooltip className="commonTooltip" id="arrowTwoTooltip" place="top" type="dark" effect='solid' />
          </Link>
          <Link className={`dropdown-item ${isActive === "lineWithDoubleArrow" ? "active" : ""}`} to="#" onClick={drawDoubleHeadArrow}>
            <i
              className="icon-a3"
              data-tip="Line Arrow" data-for="lineArrowTooltip"
            ></i>
            <ReactTooltip className="commonTooltip" id="lineArrowTooltip" place="top" type="dark" effect='solid' />
          </Link>
          <Link className={`dropdown-item ${isActive === "line" ? "active" : ""}`} to="#" onClick={drawLine}>
            <i
              className="icon-linexpand"
              data-tip="Line" data-for="lineTooltip"
            ></i>
            <ReactTooltip className="commonTooltip" id="lineTooltip" place="top" type="dark" effect='solid' />
          </Link>
          <Link to="#" className={`dropdown-item ${isActive === "lineWithSingleArrow" ? "active" : ""}`} onClick={drawSingleHeadArrow}>
            <i
              className="icon-a1"
              data-tip="Straight Arrow" data-for="straightArrowTooltip"
            ></i>
            <ReactTooltip className="commonTooltip" id="straightArrowTooltip" place="top" type="dark" effect='solid' />
          </Link>
        </div>
        <div className="range">
          <span>Size</span>
          <div className="slidecontainer">
            <input
              type="range"
              min="1"
              max="10"
              value={arrowWidth}
              className="slider"
              onChange={changeWidth}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Arrow;
