import socketIO from "socket.io-client";
import config from "../config";

export default class Socket {
  constructor() {
    this.socket = socketIO(config.SOCKET_URL);
    this.activeEvent = [];
  }

  getSocket() {
    return this.socket;
  }

  sendMessage(event, data) {
    this.socket.emit(event, data);
  }
  unSubscribeAllEvent(events = []) {
    if (events) {
      // console.log("Removing subscribe event", events);
      return events.map((event) => {
        return this.socket.off(event);
      });
    }
  }
}
