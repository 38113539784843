import { useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
const childrenShapes = ["eraser"];

function Eraser({ canvas, isActive, setActiveShape }) {

  const [drawingWidth, setDrawingWidth] = useState("10");

  function eraseObject(e) {
    e.preventDefault()
    setActiveShape("eraser")
    canvas.freeEraser(drawingWidth)
  }

  function changeWidth(event) {
    const value = event.target.value;
    canvas.freeEraser(value)
    setDrawingWidth(() => value);
  }

  return (
    <div>
      <Link to="#"
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        className={`${childrenShapes.includes(isActive) ? "active" : ""}`}
        onFocus={eraseObject}>
        <i
          className={`icon-eraser_inactive`}
          data-tip="Eraser" data-for="eraserTooltip"
        ></i>
        <ReactTooltip className="commonTooltip" id="eraserTooltip" place="top" type="dark" effect='solid' />
      </Link>
      <div
        className="dropdown-menu arrowDropdown"
        aria-labelledby="dropdownMenuLink"
      >
        <div className="range pt-0 mt-0" style={{ borderTop: 0 }}>
          <span>Size</span>
          <div className="slidecontainer">
            <input
              type="range"
              min="1"
              max="100"
              value={drawingWidth}
              className="slider"
              onChange={changeWidth}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Eraser;
