import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Loader/fullScreenLoader";
import ApiEndPoint from "../../../utilities/apiEndPoints";
import { SocketContext } from "../../../context";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import config from "../../../config";
import modelNotification from "../../../utilities/notifications";

function Uploader({ canvas, isActive, setActiveShape }) {
  function selectTool() {
    setActiveShape("upload");
  }

  let { socket } = useContext(SocketContext);
  const [isUploading, setUploading] = useState(false);
  const maxAllowedSize = parseInt(config.MEDIA_UPLOAD_SIZE) * 1024 * 1024;

  async function uploadDoc(e, type) {
    try {
      let filename = e?.target?.files[0].name;

      var ext = filename.substr(filename.lastIndexOf('.'));
      let acceptFormat = e.target.accept.split(",").map(d=>d.trim());

      if(!acceptFormat.includes(ext)){
        throw {msg:`Only ${acceptFormat.join(" ")} ${acceptFormat.length>1?"formats":"format"} are allowed`}
      }

      if (e.target.files[0].size > maxAllowedSize) {
        let err = { msg: "Please upload file less than 2 MB" }
        if(type==="pdf"){
          err.msg = getMessage("pdf")
        }else if(type==="images"){
          err.msg = getMessage("image")
        }else if(type==="docx"){
          err.msg = getMessage("document")
        }
        throw err;
      }
     
      setUploading(true);
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("type", type);
      let uploadRes = await axios.post(
        `${config.API_BASE_URL}${ApiEndPoint.uploadDoc.url}`,
        formData
      );
      if (
        uploadRes.data &&
        uploadRes.data.images &&
        uploadRes.data.images.length > 0
      ) {
        socket.emit("on-doc-link-share", uploadRes.data);
      }
      setUploading(false);
      setActiveShape("select");
      canvas.selectTool();
    } catch (errorRes) {
      setUploading(false);
      setActiveShape("select");
      canvas.selectTool();
      modelNotification({
        type: "error",
        message: "Error",
        description:
          errorRes?.data?.error[0]?.message ||
          errorRes?.response?.data?.message ||
          errorRes?.msg ||
          "Upload fail",
      });
    }
  }

  function getMessage(type){
    return `Please upload ${type} less than ${config.MEDIA_UPLOAD_SIZE} MB`
  }

  return (
    <div>
      {isUploading ? (
        <Loader>
          <h4>Uploading....</h4>
        </Loader>
      ) : (
        ""
      )}
      <Link
        to="#"
        id="dropdownMenuLink03"
        onClick={selectTool}
        data-toggle="dropdown"
        className={`dropdown-toggle ${isActive === "upload" ? "active" : ""}`}
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i
          className="icon-plus-fill"
          data-tip="Upload PDF PPT DOC"
          data-for="uploadTooltip"
        ></i>
        <ReactTooltip
          className="commonTooltip"
          id="uploadTooltip"
          place="top"
          type="dark"
          effect="solid"
        />
      </Link>
      <div
        className="dropdown-menu colorDropdown fileUpload"
        aria-labelledby="dropdownMenuLink03"
      >
        <ul className="list-inline">
          <li className="list-inline-item">
            <label htmlFor="pdfUpload">
              <input
                onChange={(e) => uploadDoc(e, "pdf")}
                type="file"
                accept=".pdf"
                name=""
                id="pdfUpload"
              />
              <i className="icon-pdficon"></i>
            </label>
          </li>
          <li className="list-inline-item">
            <label htmlFor="imageUpload">
              <input
                onChange={(e) => uploadDoc(e, "image")}
                type="file"
                accept=".jpg,.jpeg,.png"
                name=""
                id="imageUpload"
              />
              <i className="icon-images"></i>
            </label>
          </li>

          <li className="list-inline-item">
            <label htmlFor="docUpload">
              <input
                onChange={(e) => uploadDoc(e, "docx")}
                type="file"
                accept=".doc,.docx"
                name=""
                id="docUpload"
              />
              <i className="icon-docicon2"></i>
            </label>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Uploader;
