import Modal from "antd/lib/modal";
import GetStarted from "../GetStarted";

export default function GetStartedModal({visible, controller}) {
  return (
    <>
      <Modal centered visible={visible} footer={false}>
        <div className="d-flex justify-content-center">
          <GetStarted controller={controller}/>
        </div>
      </Modal>
    </>
  );
}
