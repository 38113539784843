import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import canvasReducer from "../slices/canvasSlice";
import userReducer from "../slices/userSlice";
import activeUsersReducer from "../slices/roomUsersList";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

const reducers = combineReducers({ canvasReducer, userReducer, activeUsersReducer });

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware:getDefaultMiddleware({
    serializableCheck:false
  })
});

export default store;
