import React from "react";

function InvalidPage() {
  return (
    <div className="pg-h-100 d-flex flex-column justify-content-center align-items-center ">
      <div className="jumbotron">
        <h1 className="display-4 lead">Requested room is not valid</h1>
      </div>
      <div className="lead text-center">Please check your link and try again</div>
    </div>
  );
}

export default InvalidPage;
